import React from 'react';
import logo from '../img/logo/logRia.jpg'; // Укажите путь к вашему логотипу

const AboutPage = () => {
    return (
        <div style={styles.container}>
            {/* Шапка с логотипом */}
            <header style={styles.header}>
                <img src={logo} alt="Логотип ООО «РИАгрупп М»" style={styles.logo}/>
            </header>

            <h1 style={styles.heading}>О нас</h1>
            <p style={styles.paragraph}>
                Компания <span style={styles.highlight}>ООО «РИАгрупп М»</span> — одна из структур холдинга «РИА»,
                которая успешно ведет <b>оптовую торговлю различными видами бумажной продукции и картонов.</b> Мы
                являемся официальными торговыми представителями (дистрибьюторами) нескольких крупнейших российских
                целлюлозно-бумажных комбинатов.Поставляем газетные, офсетные, мелованные, упаковочные бумаги, а так же
                мелованный
                картон для всех полиграфических предприятий, издательств и компаний у которых
                имеется потребности в данных материалах как в рулонах, так и нарезанных по формату
                исходя из потребностей Покупателя. Снабжаем офисы наших клиентов
                следующими видами офисных товаров: офисная листовая бумага, рулоны для принтеров,
                термобумага для факса, рулоны для инженерных машин, блоки для записей,
                потребительская бумага, канцелярские товары для повседневного спроса и подарочные
                комплекты.</p>
            <p> Офсетные и газетные, мелованные и легкомелованные бумаги, мелованные картоны и ряд
                других товаров находятся в постоянном наличии на нашем складе в г. Минске, что
                позволяет нам оперативно удовлетворять потребности Покупателей.
            </p>

            <h2 style={styles.subHeading}>Наша продукция</h2>
            <p style={styles.paragraph}>Мы поставляем широкий ассортимент бумажной продукции, включая:</p>
            <ul style={styles.list}>
                <li>Газетные, офсетные, мелованные и упаковочные бумаги;</li>
                <li>Мелованные картоны;</li>
                <li>Офисные товары: офисная листовая бумага, рулоны для принтеров, термобумага для факса, блоки для
                    записей, потребительская бумага и канцелярские товары.
                </li>
            </ul>
            <p style={styles.paragraph}>Вся продукция доступна как в рулонах, так и в нарезанном формате, исходя из
                потребностей наших клиентов.</p>

            <h2 style={styles.subHeading}>Наши партнёры</h2>
            <p style={styles.paragraph}>Мы сотрудничаем с ведущими производителями бумажной продукции, такими как:</p>
            <ul style={styles.list}>
                <li>АО "ГОЗНАК" РФ (филиал Краснокамская бумажная фабрика) — поставщик жиростойких и влагопрочных
                    бумаг, а также белых офсетных бумаг в рулонах и листах.
                    <p style={styles.paragraph}>
                        Подробнее о продукции  вы можете узнать на их официальном сайте:{" "}
                        <a href="https://kbf.goznak.ru" target="_blank" rel="noopener noreferrer" style={styles.link}>
                            kbf.goznak.ru
                        </a>.
                    </p>
                </li>

                <li>ООО "КАМА" — производитель офсетных, легкомелованных бумаг и мелованных картонов  GC1, GC2, UC1 и других.</li>
            </ul>
            <p style={styles.paragraph}>
                Подробнее о продукции ООО "КАМА" вы можете узнать на их официальном сайте:{" "}
                <a href="https://cbk-kama.ru/" target="_blank" rel="noopener noreferrer" style={styles.link}>
                    www.cbk-kama.ru
                </a>.
            </p>
            <p>
                В связи с полным завершением отладки процесса производства мелованных картонов у
                нашего давнего партнера ООО "КАМА" нашим клиентам доступны картоны GC1 , GC 2,
                UC 1. Кама Bright GC1 от 190 до 350 гр.кв.м. , аналогично КАМА Strong GC2 от 190 до
                350 гр.к.в.м. Линейка новых картонов повышенной жесткости Project GC2 от 250 до 330
                гр.к.в.м и высококачественный специализированный картон с улучшенными
                характеристиками мелованного слоя Tabacco GC1 и GC2, а также премиум картон GC1
                Pharma массой от 215 до 350 гр.кв.м. Параметры, рекомендации по применению,
                наличие, стоимость и время поставки вы можете уточнить у наших специалистов.
            </p>
            <ul style={styles.list}>
                <li>АО «ВОЛГА».  Газетные бумаги от 42 до 48,8 гр.кв.м. в рулонах и листах
                    <p style={styles.paragraph}>
                        Подробнее о продукции АО «ВОЛГА» вы можете узнать на их официальном сайте:{" "}
                        <a href="https://www.volga-paper.ru" target="_blank" rel="noopener noreferrer" style={styles.link}>
                            www.volga-paper.ru
                        </a>.
                    </p>
                </li>
                <li>АО «Кондопога».  Газетные бумаги от 42 до 48,8 гр.кв.м. в рулонах и листах
                    <p style={styles.paragraph}>
                        Подробнее о продукции АО «Кондопога» вы можете узнать на их официальном сайте:{" "}
                        <a href="https://www.aokcbk.ru" target="_blank" rel="noopener noreferrer" style={styles.link}>
                            www.aokcbk.ru
                        </a>.
                    </p>
                </li>
            </ul>
            <h2 style={styles.subHeading}>Наши преимущества</h2>
            <ul style={styles.list}>
                <li>Широкий ассортимент продукции в наличии на складе в г. Минске;</li>
                <li>Оперативная доставка и индивидуальный подход к каждому клиенту;</li>
                <li>Долгосрочные партнёрские отношения и гибкие условия сотрудничества.</li>
            </ul>

            <h2 style={styles.subHeading}>Наш девиз</h2>
            <p style={styles.paragraph}>
                <span style={styles.highlight}>«К УСПЕХУ С НАДЕЖНЫМИ ПАРТНЕРАМИ»</span> — это не просто слова, а принцип
                нашей работы. Мы всегда готовы идти навстречу пожеланиям наших клиентов и предлагать решения, которые
                помогут им достичь успеха.
            </p>


        </div>
    );
};

const styles = {
    container: {
        maxWidth: '1200px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
        lineHeight: '1.6',
        color: '#333',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '20px',
    },
    logo: {
        height: '100px', // Настройте высоту логотипа
        marginRight: '100px',
    },
    heading: {
        textAlign: 'center',
        color: '#32CD32',
        marginBottom: '20px',
    },
    subHeading: {
        color: '#FFD700',
        marginTop: '30px',
        marginBottom: '15px',
    },
    paragraph: {
        marginBottom: '15px',
    },
    list: {
        listStyleType: 'disc',
        marginLeft: '20px',
        marginBottom: '15px',
    },
    highlight: {
        fontWeight: 'bold',
        color: '#32CD32',
    },
    contactInfo: {
        backgroundColor: '#e6f2ff',
        padding: '15px',
        borderRadius: '5px',
        marginTop: '20px',
    },
    link: {
        color: '#0000EE',
        textDecoration: 'none',
    },
};

export default AboutPage;